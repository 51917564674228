import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import HubspotForm from "react-hubspot-form"
import AOS from 'aos';
import "aos/dist/aos.css";
import Helmet from "react-helmet"

const IntegrationListing = ({ location }) => {

    useEffect(() => {
        AOS.init({
            once:true
        });
      }, []);


  return (
    <Layout location={location}>
          <Helmet>
                <title>Quick and Easy Integrations - Vantage Circle </title>

                <meta name="description" content="Check out how Vantage Circle's platforms easily pairs with your existing HRIS tools to provide seamless user experience with swift onboarding." />
                <meta property="twitter:title" content="Quick and Easy Integrations - Vantage Circle" />
                <meta name= "robots" content= "index" />
                        
                <meta property="twitter:description" content="Check out how Vantage Circle's platforms easily pairs with your existing HRIS tools to provide seamless user experience with swift onboarding." />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://www.vantagecircle.com/integration/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <meta property="og:title" content="Quick and Easy Integrations - Vantage Circle" />

                <meta property="og:description" content="Check out how Vantage Circle's platforms easily pairs with your existing HRIS tools to provide seamless user experience with swift onboarding." />
                <link rel="canonical" href="https://www.vantagecircle.com/integration/"></link>
            </Helmet>
        <section className="w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-9 xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0">
            <div className="w-11/12 2xl:max-w-7xl xl:flex justify-center items-start z-10 lg:px-3 relative">
                <div className="w-full flex flex-col text-center lg:text-left justify-center xl:items-start items-center xl:mr-5 xl:pt-20 xl:pb-20 z-10">
                    {/* <div class="my-2 flex justify-center items-center px-4 py-2 rounded-full border border-gray-darklight bg-indigo-50">
                        <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Rewards-ico-hero.png" alt="Vantage Pulse" height="15" width="15" />
                        <h2 class="text-purple-100 text-sm lato"><span class="font-bold">&nbsp;&nbsp;VANTAGE </span>REWARDS</h2>
                    </div> */} 

                    <h1 className="text-purple-100 bannerSectionTitle-3 lg:pr-3 text-center xl:w-10/12"><span className="relative"><img decoding="async" class="absolute -bottom-1 right-0 xl:right-0" src="https://res.cloudinary.com/vantagecircle/image/upload/v1648619895/gatsbycms/uploads/2022/03/hero-strokesvg.svg" /></span>Enjoy seamless <span className='text-orange'> Integration</span>,<br/> for an effortless <span className='text-orange'>Experience</span></h1>


                    <p className="my-8 text-lg xl:text-xl text-center xl:text-left text-purple-100 md:w-10/12">Integrate Vantage Circle right inside your existing tools hassle-free<br/> and recognize outstanding work the moment it happens!</p>
                    <div className="xl:flex justify-center items-center lg:justify-start w-4/6 md:w-4/12 lg:w-1/4 xl:w-1/4 2xl:w-1/4 cursor-pointer my-8">
                        <a className="vc-new-white-border-btn-rounded-full lato rounded-full mb-3 xl:mb-0 xl:mr-2" href="/request-demo/">Request a demo</a>
                        {/* <a className="vc-new-white-border-btn-rounded-full lato rounded-full mt-3 xl:mt-0 xl:ml-2" href="#video">Watch Video</a> */}
                    </div>
                </div>     
                <div className='w-full xl:w-5/12 h-64 md:h-96 md:my-9 my-0 xl:h-80'></div>   
               
                <div class="absolute -bottom-28 md:-bottom-72 md:left-10 lg:right-1/4 xl:-bottom-24 xl:right-0 flex justify-center xl:justify-end items-center h-1/2 md:h-full z-0 mt-6 xl:mt-0 lg:ml-3 px-3" data-aos="fade-up" data-aos-duration="500" data-aos-delay= "600"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Hero-1.webp" alt="Vantage Rewards" width="600" /></div>
            </div>
            <div className='absolute right-0 top-0'><img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/hero-oval.webp" width= "500" /></div>
        </section>
        <section className='max-w-7xl mx-auto my-20 px-5 xl:px-0'>
            <h2 className='text-center text-3xl xl:text-5xl'>Select Integration Type</h2>
            <div className='xl:grid grid-cols-3 mx-auto max-w-5xl md:w-1/2 xl:w-full my-10 gap-10'>
                <a href="#chat-collaboration" className='shadow-lg hover:border hover:border-indigo-100 transition duration-500 transform hover:-translate-y-2 flex justify-center rounded-lg px-2 py-5 md:py-8 xl:py-5 border border-purple-100 my-10'>
                    <p className='m-0 text-center text-gray-500'>Chat & Collaboration</p>
                </a>
                <a href="#sso" className='shadow-lg hover:border hover:border-indigo-100 transition duration-500 transform hover:-translate-y-2 flex justify-center rounded-lg px-2 py-5 md:py-8 xl:py-5 border border-purple-100 my-10'>
                    <p className='m-0 text-center text-gray-500'>Single Sign On (SSO)</p>
                </a>
                <a href="#hcm-hris" className='shadow-lg hover:border hover:border-indigo-100 transition duration-500 transform hover:-translate-y-2 rounded-lg px-2 flex justify-center py-5 md:py-8 xl:py-5 border border-purple-100 my-10'>
                    <p className='m-0 text-center text-gray-500'>HCM/HRIS Platform</p>
                </a>
            </div>
            
            <div class="max-w-7xl mx-auto mt-10 md:mt-20 md:px-5 xl:px-0" id="chat-collaboration">
                <h3 class="section-heading">Chat &amp; Collaboration</h3>
                <div class="grid grid-cols-1 gap-5 md:gap-9 sm:grid-cols-2 md:grid-cols-3 items-center">
                    <div class="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded overflow-hidden shadow-lg">
                        <div class="bg-white p-8 h-full">
                            <div class="relative plect-cut">
                                <img decoding="async" loading="lazy" width="50" height="50" class="" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/ms-teams" alt="MS Teams" />
                            </div>
                            <h3 class="box-title">Microsoft Teams</h3>
                            <p class="box-subtitle md:h-16">Uplift employee morale with peer-to-peer recognitions directly from Microsoft Teams.</p>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="/integration/microsoft-teams/">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase">Know more</span>
                                    <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded overflow-hidden shadow-lg">
                        <div class="bg-white p-8 h-full">
                            <div class="relative plect-cut">
                                <img decoding="async" loading="lazy" width="50" height="50" class="" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/Slack.png" alt="Slacks" />
                            </div>
                            <h3 class="box-title">Slack</h3>
                            <p class="box-subtitle md:h-16">Enhance your efforts of recognition, communication, and collaboration with Slack.</p>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="/integration/slack/">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase">Know more</span>
                                    <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded overflow-hidden shadow-lg">
                        <div class="bg-white p-8 h-full">
                            <div class="relative plect-cut">
                                <img decoding="async" loading="lazy" width="50" height="50" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/Workplace.png" alt="Workplace" />
                            </div>
                            <h3 class="box-title">Workplace</h3>
                            <p class="box-subtitle md:h-16">Boost employee morale by instant recognition with Workplace.</p>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="/contact-us/">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase">Contact us</span>
                                    <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded overflow-hidden shadow-lg">
                        <div class="bg-white p-8 h-full">
                            <div class="relative plect-cut">
                                <img decoding="async" loading="lazy" width="50" height="50" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/Yammer.png" alt="Yammer" />
                            </div>
                            <h3 class="box-title">Yammer</h3>
                            <p class="box-subtitle md:h-16">Better decisions mean rapid progress of the organization. Work smarter together with Vantage Circle and Yammer.</p>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="/contact-us/">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase">Contact us</span>
                                    <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="max-w-7xl mx-auto mt-10 md:mt-20 md:px-5 xl:px-0" id="sso">
                <h3 class="section-heading">Single Sign-On(SSO)</h3>
                <div class="grid grid-cols-1 gap-5 md:gap-9 sm:grid-cols-2 md:grid-cols-3 items-center">
                    <div class="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded overflow-hidden shadow-lg">
                        <div class="bg-white p-8 h-full">
                            <div class="relative plect-cut">
                                <img decoding="async" loading="lazy" width="50" height="50" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/Okta.png" alt="Okta" />
                            </div>
                            <h3 class="box-title">Okta</h3>
                            <p class="box-subtitle md:h-16">Secure authentication with Okta Single Sign-On process.</p>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="/contact-us/">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase">Contact us</span>
                                    <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded overflow-hidden shadow-lg">
                        <div class="bg-white p-8 h-full">
                            <div class="relative plect-cut">
                                <img decoding="async" loading="lazy" width="50" height="50" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/Azure.png" alt="Azure" />
                            </div>
                            <h3 class="box-title">Azure</h3>
                            <p class="box-subtitle md:h-16">Enhanced security and authentication with Azure.</p>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="/contact-us/">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase">Contact us</span>
                                    <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded overflow-hidden shadow-lg">
                        <div class="bg-white p-8 h-full">
                            <div class="relative plect-cut">
                                <img decoding="async" loading="lazy" width="50" height="50" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/office-365.png" alt="Office 365" />
                            </div>
                            <h3 class="box-title">Office 365</h3>
                            <p class="box-subtitle md:h-16">Provide a seamless login experience with Office 365 Single Sign-On.</p>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="/contact-us/">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase">Contact us</span>
                                    <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="max-w-7xl mx-auto mt-10 md:mt-20 md:px-5 xl:px-0" id="hcm-hris">
                <h3 class="section-heading">HCM/HRIS Platform</h3>
                <div class="grid grid-cols-1 gap-5 md:gap-9 sm:grid-cols-2 md:grid-cols-3 items-center">
                    <div class="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded overflow-hidden shadow-lg">
                        <div class="bg-white p-8 h-full">
                            <div class="relative plect-cut">
                                <img decoding="async" loading="lazy" width="50" height="50" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/people-strong.png" alt="People Strong" />
                            </div>
                            <h3 class="box-title">People Strong</h3>
                            <p class="box-subtitle md:h-16">Experience people-first culture with People Strong seamless integration.</p>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="/contact-us/">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase">Contact us</span>
                                    <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded overflow-hidden shadow-lg">
                        <div class="bg-white p-8 h-full">
                            <div class="relative plect-cut">
                                <img decoding="async" loading="lazy" width="50" height="50" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/zoho.png" alt="Zoho People" />
                            </div>
                            <h3 class="box-title">Zoho People</h3>
                            <p class="box-subtitle md:h-16">Engage, recognize &amp; motivate your workplace by integrating ZohoPeople with Vantage Circle.</p>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="/contact-us/">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase">Contact us</span>
                                    <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded overflow-hidden shadow-lg">
                        <div class="bg-white p-8 h-full">
                            <div class="relative plect-cut">
                                <img decoding="async" loading="lazy" width="50" height="50" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/freshteam.png" alt="Freshteam" />
                            </div>
                            <h3 class="box-title">Freshteam</h3>
                            <p class="box-subtitle md:h-16">With our easy-to-integrate process, now connect with Freshteam for a smoother working experience.</p>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="/contact-us/">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase">Contact us</span>
                                    <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded overflow-hidden shadow-lg">
                        <div class="bg-white p-8 h-full">
                            <div class="relative plect-cut">
                                <img decoding="async" loading="lazy" width="50" height="50" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/SAP.png" alt="SAP" />
                            </div>
                            <h3 class="box-title">SAP</h3>
                            <p class="box-subtitle md:h-16">Seamless environment to communicate, engage and recognize with SAP SuccessFactors.</p>
                            <div class="learnmore-btn mt-5 mb-0">
                                <a class="text-purple-500 inline-flex items-center" href="/contact-us/">
                                    <span class="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                    <span class="text-purple-500 font-medium text-sm uppercase">Contact us</span>
                                    <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='max-w-7xl mx-auto my-5 xl:my-28 md:flex justify-center items-center p-5 xl:p-0'>
            <div className='flex justify-start items-center w-full md:w-1/2 xl:w-6/12 pr-5'>
                <img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Footer-.webp" width= "500" />
            </div>
            <div className='flex flex-col justify-center items-center mt-16 xl:my-0 w-full md:w-1/2 xl:w-6/12 pl-5'>
                <h2 className='text-3xl xl:text-5xl'>Get more out of your <span className='text-orange'>everyday tools</span></h2>
                <ul className='orangebullets flex flex-col items-start w-full mt-5 xl:my-10'>
                    <li className='py-3 pl-2 text-xl xl:text-2xl'>Easy onboarding and reduced manual effort with HRMS/HRIS integrations</li>
                    <li className='py-3 pl-2 text-xl xl:text-2xl'>Increase productivity and belonging with your existing chat and collaboration tools</li>
                    <li className='py-3 pl-2 text-xl xl:text-2xl'>Save time with reduced app shuffle and easy access</li>
                    <li className='py-3 pl-2 text-xl xl:text-2xl'>Make the program more impactful and aligned with goals</li>
                    <li className='py-3 pl-2 text-xl xl:text-2xl'>Streamline workflow with efficient and secure data flow</li>
                </ul>
            </div>
        </section>
        <section class="bottom-cta m-6 md:m-0 relative z-1">
            <div class="container max-w-5xl bg-indigo-100 rounded-lg shadow-xl p-10 md:p-14 relative z-9 overflow-hidden my-20">
                <div class="bottom-cta-content text-center text-white">
                    <div class="section-title-white">Want to learn more about Integration?</div>
                    <div class="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                        <div className='w-10/12 md:w-1/3 xl:w-3/12 lg:w-auto'>
                            <a class="vc-new-orange-btn-rounded-full" href="/request-demo/">Request a demo</a>
                        </div>
                    </div>
                </div>
                <img decoding="async" loading="lazy" class="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png" alt="VC white dots" width="90" height="90" />
                <img decoding="async" loading="lazy" class="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg" alt="VC flower" width="150" height="150" />
            </div>                         
        </section>
        
    </Layout>
  )
}

export default IntegrationListing